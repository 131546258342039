import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import CategoriesContentSlider from "../../components/Sections/CategoriesMainSlider"
import Seo from "../../components/seo"

const CategoriesContent = ({ data }) => {
  return (
    <Layout>
      <Seo title={data.wpCategory.name} />
      <div className="container boxed-container container_ohidden">
        <div>
          <h1 className="projects_title">{data.wpCategory.name}</h1>
        </div>
        <CategoriesContentSlider categoryData={data} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Commercial" } } } }
      }
    ) {
      nodes {
        id
        title
        link
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  transformOptions: { cropFocus: CENTER }
                  aspectRatio: 1.5
                )
              }
            }
          }
        }
      }
    }
    wpCategory(name: {eq: "Commercial"}) {
      name
    }
  }
`

export default CategoriesContent
